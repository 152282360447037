import React from 'react'
import './App.css';
import StepperDesktop from './functions/StepperDesktop'
import StepperMobile from './functions/StepperMobile'
import { useMediaQuery } from 'react-responsive'


const styles = {
  body: {
    minHeight: 800,
    position: "relative"
  },
  copyright: {
    fontSize: 15,
    width: "100%",
    height: "70px",
    position: "fixed",
    bottom: "0%",
    backgroundColor: "rgba(0,0,0,.7)",
    color: "white"
  }
}

function App() {

  const year = new Date().getFullYear();


  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <React.Fragment>
      <div style={styles.body} className="App main-box">
      <br/>
      <br/>
        {
          isMobile ? <StepperMobile /> : <StepperDesktop />
        }
      </div>
      
      <div style={styles.copyright} className="flex-center">
        <p>
          © {year} Nutribram | Hecho con ♡ por Luis Gaspar
        </p>
      </div>
    </React.Fragment>
  );
}

export default App;
