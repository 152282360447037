import { refType } from "@mui/utils";

// Check empty fields
export const isEmpty = (string) => {
    if (!string) return true;
    else return false;
}

// Check is a valid Email
export const isEmail = (email) => {
    
    // Regular expresion to email
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Validation
    if (!email) return false;
    else if (email.match(regEx)) return true;
    else return false;
}

export const isPhoneNumber = (phone) => {
    const regEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (phone.match(regEx)) return true;
    else return false;
}

export const isUrl = (url) => {
    const regEx = /^(ftp|http|https):\/\/[^ "]+$/.test(url);
    if (url.match(regEx)) return true;
    else return false
}

export const isValidName = (name) => {

    const regEx = /^[a-zA-Z ]+$/;

    if (
        !isEmpty(name) &&
        isString(name) &&
        name.length < 50 &&
        name.match(regEx)
    )
    return true;
    
    else return false;
}

export const isValidNumber = (number) => {
    //const regEx = /^[0-9]?(\.[0-9][0-9]?)*$/
    const regEx = /^\d{0,3}(\.\d{1,2})?$/

    if (
        number &&
        number.match(regEx)
    )
    return true;

    else return false;

}

export const isInteger = (number) => {
    
    const regEx = /^[0-9]*$/

    if (
        number &&
        number.match(regEx)
    )
    return true;

    else return false;

}


export const isValidPassword = (password) => {
    if (!isEmpty(password)) return true;
    else return false;
}

export const isWeakPassword = (password) => {
    if (password.length < 8) return true;
    else return false;
}

// Type data validation
export const isBoolean = (data) => typeof data === 'boolean' ? true : false;
export const isString =  (data) => typeof data === 'string' ? true : false;
export const isNumber = (data) => typeof data === 'number' ? true : false;
export const isObject = (data) => typeof data === 'object' ? true : false;
export const isArray = (data) => Array.isArray(data);
export const isUndefined = (data) => typeof data === 'undefined' ? true : false;
export const isDate = (data) => typeof new Date(data).getMonth === 'function' ? true : false;

export const isAuthenticated = () => {
    return null;
}