import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { isValidName, isValidNumber, isInteger } from './Validators';
import { toTitleCase } from './Utils';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AlertTitle from '@mui/material/AlertTitle';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';




const steps = ['Datos', 'Info', 'Resultado'];


const styles = {
    content: {
        position: "relative",
        width: "100%",
        height: "100%",
        padding: "50px 5%"
    },
    container: {
        width: "100%",
        height: "auto",
    },
    box: {
        width: "100%",
        padding: "50px 0",
        height: "auto"
    },
    form: {
        width: "100%"
    }
}



export default function StepperMobile() {


    // Store temporary data
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [gender, setGender] = useState("female");
    const [activity, setActivity] = useState("no");
    
    const [activityTimes, setActivityTimes] = useState(1);
    const [activityFactor, setActivityFactor] = useState(1.2);

    // Errors
    const [errorName, setErrorName] = useState("");
    const [errorAge, setErrorAge] = useState("");
    const [errorWeight, setErrorWeight] = useState("");
    const [errorHeight, setErrorHeight] = useState("");

    const [error, setError] = useState("");
    const [calories, setCalories] = useState(0);
    const [imc, setImc] = useState(0);
    const [diagnostic, setDiagnostic] = useState({text: "", severity: "info"})

    // Validate Name
    const validateName = (name) => {
        if (!name) setErrorName("Debes ingresar tu nombre");
        else if (!isValidName(name)) setErrorName("Tu nombre no es válido");
        else setErrorName("");
    }

    // Validate Age
    const validateAge = (age) => {
        if (!age) setErrorAge("Debes ingresar tu edad");
        else if (!isInteger(age)) setErrorAge("Edad no es válida. Solo puedes ingresar números enteros");
        else if (age < 1 || age > 120) setErrorAge("¡No eres humano! Esta herramienta no es para ti :(");
        else setErrorAge("");
    }

    // Validate Weight
    const validateWeight = (weight) => {
        if (!weight) setErrorWeight("Debes ingresar tu peso para el càlculo del IMC");
        else if (!isValidNumber(weight)) setErrorWeight("Tu peso no es válido. Solo puedes ingresar números enteros con 2 decimales máximo");
        else if (weight < 1 || weight > 500) setErrorWeight("Esta herramienta no es para ti. Consulta con un profesional. :(");
        else setErrorWeight("");
    }

    // Validate Weight
    const validateHeight = (weight) => {
        if (!weight) setErrorHeight("Debes ingresar tu estatura para el cálculo del IMC");
        else if (!isValidNumber(weight)) setErrorHeight("Tu estatura no es válida. Solo puedes ingresar números enteros con 2 decimales máximo");
        else if (weight < 0 || weight > 3) setErrorHeight("Esta solo es para humanos. No podemos ayudarte :(");
        else setErrorHeight("");
    }

    const confirmation = () => {

        // Validate is exist empty fields
        if (!name || !age || !weight || !height || !gender || !activity) {
            setError("Datos incompletos. No fue posible realizar la operación :(");
        }
        
        // Calculate calories
        let caloriesResult;

        if (gender === 'female') {
            caloriesResult = Math.round(((655 + (9.6 * weight)) + (1.8 * (height * 100)) - (4.7 * age)) * activityFactor);
            setCalories(caloriesResult);
        }
        else {
            caloriesResult = Math.round(((66 + (13.7 * weight)) + (5 * (height * 100)) - (6.8 * age)) * activityFactor);
            setCalories(caloriesResult);
        }

        // Calculate IMC
        let imcResult = Math.round(weight / Math.pow(height, 2));
        setImc(imcResult);

        if (imcResult < 16) setDiagnostic({
            text: "infrapeso con delgadez severa",
            severity: "error"
        })
        else if (imcResult >= 16 && imcResult < 17 ) setDiagnostic({
            text: "infrapeso con delgadez moderada",
            severity: "error"
        })
        else if (imcResult >= 17 && imcResult < 18.50 ) setDiagnostic({
            text: "infrapeso con delgadez aceptable",
            severity: "warning"
        })
        else if (imcResult >= 18.50 && imcResult <= 25 ) setDiagnostic({
            text: "peso normal",
            severity: "success"
        })
        else if (imcResult > 25 && imcResult <= 30 ) setDiagnostic({
            text: "sobrepeso",
            severity: "warning"
        })
        else if (imcResult > 30 && imcResult <= 35 ) setDiagnostic({
            text: "obesidad tipo 1",
            severity: "error"
        })
        else if (imcResult > 35 && imcResult <= 40 ) setDiagnostic({
            text: "obesidad tipo 2",
            severity: "error"
        })
        else if (imcResult > 40) setDiagnostic({
            text: "obesidad tipo 3",
            severity: "error"
        })
        else setDiagnostic({
            text: "No pudimos realizar el cálculo. Consulta con un profesional de la Salud.",
            severity: "info"
        })
    }

    // Update Activity Data
    const updateActivity = (value) => {
        setActivity(value);
        if (value === 'no') {
            setActivityFactor(1.2);
        }
        else updateActivityFactor(activityTimes);
    }
    const updateActivityFactor = (times) => {
        setActivityTimes(times);
        if (times >= 1 && times <= 3) setActivityFactor(1.375);
        else if (times >= 4 && times <= 5) setActivityFactor(1.55);
        else if (times >= 6 && times <= 7) setActivityFactor(1.725);
        else setActivityFactor(1.2);
    }

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {

    if (activeStep === 2) confirmation();

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const cleanFields = () => {
    setName("")
    setAge("")
    setWeight("")
    setHeight("")
    setGender("female")
    setActivity("no")
    setActivityTimes(1)
    setActivityFactor(1.2)
    setCalories(0)
    setImc(0)
    setDiagnostic({text: "", severity: "info"})
    setErrorName("")
    setErrorAge("")
    setErrorWeight("")
    setErrorHeight("")
  }

  const handleReset = () => {
    cleanFields();
    setActiveStep(0);
  };

  return (
    <div style={styles.content}>
    <div style={styles.container}>
    
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <div style={styles.form} className="flex-column">
        
          <Typography sx={{ mt: 2, mb: 1 }}>
                {`¡Muy bien ${name}! Aquí tienes tu resultado:`}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} >
                

            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity={diagnostic.severity}>
                    <AlertTitle>{`Actualmente tienes ${diagnostic.text}`}</AlertTitle>
                    Tu Indice de Masa Corporal es de <strong>{imc}</strong>
                </Alert>
            </Stack>
            <Stack>
                <p style={{fontSize: 16}}>
                    <strong>
                        {`La cantidad recomendada de calorìas que debes consumir es de ${calories}`}
                    </strong>
                </p>
            </Stack>
            <Stack>
                <p style={{fontSize: 14}}>
                Importante mencionar que el resultado solo es una referencia y no reemplaza la asesorìa de un profesional de la Salud.
                </p>
            </Stack>

            </Box>
          </Box>
          <Button onClick={handleReset}>Reiniciar</Button>
        
        </div>
      )
      :
      (
        <div style={styles.form}>
            
            <Box sx={{ mt: 2, mb: 1 }}>
            {
                activeStep === 0 ?
                <div style={styles.box} className="flex-column">
                    <TextField
                        fullWidth
                        label="Nombre"
                        value={name}
                        variant="outlined"
                        error={errorName ? true : false}
                        helperText={!errorName ? "" : errorName }
                        onChange={ e => setName(toTitleCase(e.target.value))}
                        onBlur={(e => validateName(e.target.value))}
                    />
                    <br />
                    <TextField
                        fullWidth
                        label="Edad"
                        value={age}
                        variant="outlined"
                        error={errorAge ? true : false}
                        helperText={!errorAge ? "" : errorAge }
                        onChange={ e => setAge(e.target.value)}
                        onBlur={(e => validateAge(e.target.value))}
                    />
                    <br />
                    <TextField
                        fullWidth
                        label="Peso (Kg)"
                        value={weight}
                        variant="outlined"
                        error={errorWeight ? true : false}
                        helperText={!errorWeight ? "" : errorWeight }
                        onChange={ e => setWeight(e.target.value)}
                        onBlur={(e => validateWeight(e.target.value))}
                    />
                    <br />
                    <TextField
                        fullWidth
                        label="Estatura (metros)"
                        value={height}
                        variant="outlined"
                        error={errorHeight ? true : false}
                        helperText={!errorHeight ? "" : errorHeight }
                        onChange={ e => setHeight(e.target.value)}
                        onBlur={(e => validateHeight(e.target.value))}
                    />
                </div> : ""
            }
            {
                activeStep === 1 ?
                <div style={styles.box}>
                    
                    <FormControl>
                        <FormLabel>Selecciona tu género</FormLabel>
                        <RadioGroup
                            row
                            value={gender}
                            onChange={e => setGender(e.target.value)}
                        >
                            <FormControlLabel
                                value="female"
                                control={<Radio/>}
                                label="Mujer"
                        />
                            <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label="Hombre"
                            />
                            
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <br />
                    <FormControl>
                        <FormLabel>¿Realizas algún tipo de actividad física?</FormLabel>
                        <RadioGroup
                            className="flex-center"
                            row
                            value={activity}
                            onChange={e => updateActivity(e.target.value)}
                        >
                            <FormControlLabel
                                value="si"
                                control={<Radio/>}
                                label="Sí"
                        />
                            <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                            />
                            
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <br />
                    { activity === "si" ?
                        <FormControl fullWidth>
                        <FormLabel>¿Cuántas veces por semana?</FormLabel>
                            <Select
                            value={activityTimes}
                            onChange={e => updateActivityFactor(e.target.value)}
                            >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                        </Select>
                        </FormControl> : ""
                    }
                </div> : ""
            }
            {
                activeStep === 2 ?
                <div style={styles.box}>
                    <FormControl>
                        <FormLabel>Confirma que tus datos sean correctos.</FormLabel>

                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={`Te llamas ${name}`} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <HourglassTopIcon />
                                </ListItemIcon>
                                <ListItemText primary={`Tienes ${age} años`} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <MonitorWeightIcon />
                                </ListItemIcon>
                                <ListItemText primary={`Tu peso es de ${weight}kg`} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <SquareFootIcon />
                                </ListItemIcon>
                                <ListItemText primary={`Mides ${height} metros`} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    {gender === 'female' ? <FemaleIcon /> : <MaleIcon />}
                                </ListItemIcon>
                                <ListItemText primary={`Eres ${gender === "female" ? "mujer" : "hombre"}`} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <SportsGymnasticsIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={activity === "si" ?
                                    `Realizas actividad física ${activityTimes} ${activityTimes===1?"vez":"veces"} por semana`
                                    :
                                    "No realizas actividad física"} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </FormControl>
                </div> : ""
            }
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                
            <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
            >
                Atrás
            </Button>

                <Box sx={{ flex: '1 1 auto' }}>
                    
                </Box>

            <Button
                onClick={handleNext}
                disabled={
                    activeStep===0 && errorName ||
                    activeStep===0 && errorAge ||
                    activeStep===0 && errorWeight ||
                    activeStep===0 && errorHeight ||
                    activeStep===0 && !name ||
                    activeStep===0 && !age ||
                    activeStep===0 && !weight ||
                    activeStep===0 && !height
                    ?
                    true : false
                }
                
            >
              {activeStep === steps.length - 1 ? 'Confirmar' : 'Siguiente'}
            </Button>
          </Box>
        </div>
        )}
        </Box>
        </div>
    </div>
  );
}